<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <b-row>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong> App 10 link Setting's</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                        <ValidationObserver ref="form6"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(link))" @reset.prevent="reset" >
                                                <div class='row'>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 1" vid="link_1" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 1
                                                            </template>
                                                            <b-form-input
                                                                id="link_1"
                                                                v-model="link.link_1"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 2" vid="link_2" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 2
                                                            </template>
                                                            <b-form-input
                                                                id="link_2"
                                                                v-model="link.link_2"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 3" vid="link_3" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 3
                                                            </template>
                                                            <b-form-input
                                                                id="link_3"
                                                                v-model="link.link_3"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 4" vid="link_4" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 4
                                                            </template>
                                                            <b-form-input
                                                                id="link_4"
                                                                v-model="link.link_4"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 5" vid="link_5" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 5
                                                            </template>
                                                            <b-form-input
                                                                id="link_5"
                                                                v-model="link.link_5"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 6" vid="link_6" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 6
                                                            </template>
                                                            <b-form-input
                                                                id="link_6"
                                                                v-model="link.link_6"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 7" vid="link_7" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 7
                                                            </template>
                                                            <b-form-input
                                                                id="link_7"
                                                                v-model="link.link_7"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 8" vid="link_8" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 8
                                                            </template>
                                                            <b-form-input
                                                                id="link_8"
                                                                v-model="link.link_8"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 9" vid="link_9" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 9
                                                            </template>
                                                            <b-form-input
                                                                id="link_9"
                                                                v-model="link.link_9"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 10" vid="link_10" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 10
                                                            </template>
                                                            <b-form-input
                                                                id="link_10"
                                                                v-model="link.link_10"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 11" vid="link_11" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 11
                                                            </template>
                                                            <b-form-input
                                                                id="link_11"
                                                                v-model="link.link_11"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 12" vid="link_12" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 12
                                                            </template>
                                                            <b-form-input
                                                                id="link_12"
                                                                v-model="link.link_12"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 13" vid="link_13" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 13
                                                            </template>
                                                            <b-form-input
                                                                id="link_13"
                                                                v-model="link.link_13"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 14" vid="link_14" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 14
                                                            </template>
                                                            <b-form-input
                                                                id="link_14"
                                                                v-model="link.link_14"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 15" vid="link_15" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 15
                                                            </template>
                                                            <b-form-input
                                                                id="link_15"
                                                                v-model="link.link_15"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 16" vid="link_16" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 16
                                                            </template>
                                                            <b-form-input
                                                                id="link_16"
                                                                v-model="link.link_16"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 17" vid="link_17" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 17
                                                            </template>
                                                            <b-form-input
                                                                id="link_17"
                                                                v-model="link.link_17"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 18" vid="link_18" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 18
                                                            </template>
                                                            <b-form-input
                                                                id="link_18"
                                                                v-model="link.link_18"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 19" vid="link_19" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 19
                                                            </template>
                                                            <b-form-input
                                                                id="link_19"
                                                                v-model="link.link_19"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 20" vid="link_20" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 20
                                                            </template>
                                                            <b-form-input
                                                                id="link_20"
                                                                v-model="link.link_20"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 21" vid="link_21" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 21
                                                            </template>
                                                            <b-form-input
                                                                id="link_21"
                                                                v-model="link.link_21"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 22" vid="link_22" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 22
                                                            </template>
                                                            <b-form-input
                                                                id="link_22"
                                                                v-model="link.link_22"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 23" vid="link_23" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 23
                                                            </template>
                                                            <b-form-input
                                                                id="link_23"
                                                                v-model="link.link_23"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 24" vid="link_24" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 24
                                                            </template>
                                                            <b-form-input
                                                                id="link_24"
                                                                v-model="link.link_24"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 25" vid="link_25" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 25
                                                            </template>
                                                            <b-form-input
                                                                id="link_25"
                                                                v-model="link.link_25"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 26" vid="link_26" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 26
                                                            </template>
                                                            <b-form-input
                                                                id="link_26"
                                                                v-model="link.link_26"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 27" vid="link_27" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 27
                                                            </template>
                                                            <b-form-input
                                                                id="link_27"
                                                                v-model="link.link_27"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 28" vid="link_28" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 28
                                                            </template>
                                                            <b-form-input
                                                                id="link_28"
                                                                v-model="link.link_28"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 29" vid="link_29" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 29
                                                            </template>
                                                            <b-form-input
                                                                id="link_29"
                                                                v-model="link.link_29"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 30" vid="link_30" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 30
                                                            </template>
                                                            <b-form-input
                                                                id="link_30"
                                                                v-model="link.link_30"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 31" vid="link_31" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 31
                                                            </template>
                                                            <b-form-input
                                                                id="link_31"
                                                                v-model="link.link_31"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 32" vid="link_32" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 32
                                                            </template>
                                                            <b-form-input
                                                                id="link_32"
                                                                v-model="link.link_32"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 33" vid="link_33" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 33
                                                            </template>
                                                            <b-form-input
                                                                id="link_33"
                                                                v-model="link.link_33"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 34" vid="link_34" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 34
                                                            </template>
                                                            <b-form-input
                                                                id="link_34"
                                                                v-model="link.link_34"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Link 35" vid="link_35" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            Visit Link 35
                                                            </template>
                                                            <b-form-input
                                                                id="link_35"
                                                                v-model="link.link_35"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
	this.getData()
  },
  data () {
    return {
      link: {
        howwork: '',
        telegram: '',
        fb_Link: ''
      },
      editId: ''
    }
  },
  computed: {
	loading () {
		return this.$store.state.static.loading
	}
  },
  methods: {
    getData () {
        this.$store.dispatch('mutedLoad', { loading: true})
        RestApi.getData(baseUrl, 'api/app-update/link-show').then(response => {
            if (response.success) {
                this.link = {
                    link_1: response.data.link_1,
                    link_2: response.data.link_2,
                    link_3: response.data.link_3,
                    link_4: response.data.link_4,
                    link_5: response.data.link_5,
                    link_6: response.data.link_6,
                    link_7: response.data.link_7,
                    link_8: response.data.link_8,
                    link_9: response.data.link_9,
                    link_10: response.data.link_10,
                    link_11: response.data.link_11,
                    link_12: response.data.link_12,
                    link_13: response.data.link_13,
                    link_14: response.data.link_14,
                    link_15: response.data.link_15,
                    link_16: response.data.link_16,
                    link_17: response.data.link_17,
                    link_18: response.data.link_18,
                    link_19: response.data.link_19,
                    link_20: response.data.link_20,
                    link_21: response.data.link_21,
                    link_22: response.data.link_22,
                    link_23: response.data.link_23,
                    link_24: response.data.link_24,
                    link_25: response.data.link_25,
                    link_26: response.data.link_26,
                    link_27: response.data.link_27,
                    link_28: response.data.link_28,
                    link_29: response.data.link_29,
                    link_30: response.data.link_30,
                    link_31: response.data.link_31,
                    link_32: response.data.link_32,
                    link_33: response.data.link_33,
                    link_34: response.data.link_34,
                    link_35: response.data.link_35
                }
                this.about = {
                    aboutus: response.data.aboutus
                }
            }
            this.$store.dispatch('mutedLoad', { loading: false })
        })
    },
    async register (Obj) {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null
        result = await RestApi.postData(baseUrl, `api/app-update/link`, Obj)
        this.$store.dispatch('mutedLoad', { loading: false })
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
